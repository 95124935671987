<template>
  <q-page class="category-page" v-if="categories">
    <CategoriesBarMenu/>
    <!-- Menú indicator -->
    <div class="section__bar">
      <a href="/">
        <img src="~assets/icons/home_alt_outline.svg" alt="home" width="20" height="20">
      </a>
      <div class="flex q-ml-sm">
        <p>></p>
        <p class="q-ml-sm cursor-pointer" @click="getProductsByCategory">{{categories.category.name}}</p>
      </div>
      <div class="flex q-ml-sm" v-if="subcategory_level_2.level_1">
        <p>></p>
        <p class="q-ml-sm cursor-pointer">{{subcategory_level_2.level_1}}</p>
      </div>
      <div class="flex q-ml-sm" v-if="subcategory_level_2.id">
        <p>></p>
        <p class="q-ml-sm">{{subcategory_level_2.name}}</p>
      </div>
    </div>

    <div class="row">
      <CategoriesLateralMenu
        :key="subcategory_level_2.id"
        :categories=categories
        :subcategory_level_2=subcategory_level_2
        @getProductsBySubcategoryNoSecondLevel=getProductsBySubcategoryNoSecondLevel
        @getProductsBySubcategoryFirstLevel=getProductsBySubcategoryFirstLevel
        @getProductsBySubcategory=getProductsBySubcategory
        @getProductsByCategory=getProductsByCategory>
      </CategoriesLateralMenu>
      <!-- Products container -->
      <div class="col-9 q-pa-md products-container">
        <div class="flex items-center q-mx-sm">
          <p class="text-bold q-mt-md q-mr-auto category-name">{{categories.category.name}}</p>
          <!-- <ProductsFilter
            @filterOrderBy=filterOrderBy
            :categories=categories
            @getProductsBySubcategory=getProductsBySubcategory>
          </ProductsFilter> -->
          <ProductsFilter
          @filterOrderBy=filterOrderBy
          :categories=categories
          :subcategory_level_2=subcategory_level_2
          @getProductsBySubcategoryNoSecondLevel=getProductsBySubcategoryNoSecondLevel
          @getProductsBySubcategoryFirstLevel=getProductsBySubcategoryFirstLevel
          @getProductsBySubcategory=getProductsBySubcategory
          @getProductsByCategory=getProductsByCategory
          ></ProductsFilter>
        </div>
        <div class="items__container row" v-if="products.length > 0">
          <div :product="product" v-for="(product) in products" class="col-md-4 col-xs-6" :key="product.id">
            <ExploreCardSection :product="product"></ExploreCardSection>
          </div>
        </div>
        <div v-else>
          <p>No se encontraron productos</p>
        </div>
      </div>
      
    </div>
  </q-page>
</template>

<script>
import ExploreCardSection from '../components/ExploreCardSection'
import CategoriesBarMenu from '../components/CategoriesBarMenu'
import ProductsFilter from '../components/ProductsFilter'
import axios from 'axios'
import CategoriesLateralMenu from '../components/CategoriesLateralMenu'
// import Categories from '../components/Categories'
import Vue2Filters from 'vue2-filters'

export default {
  mixins: [Vue2Filters.mixin],
  name: 'PageIndex',
  components: {
    ExploreCardSection,
    CategoriesBarMenu,
    ProductsFilter,
    CategoriesLateralMenu
    // Categories
  },
  beforeCreate: function () {
    document.body.className = 'products'
  },
  data () {
    return {
      go_sign_up: false,
      add_product: false,
      quantity_kg: 0.1,
      quantity_pz: 1,
      quantity__choice: false,
      cut: false,
      kg: true,
      pieza: false,
      choice_type: '',
      products: [],
      app_url: process.env.VUE_APP_API_URL,
      add_product_modal_product: null,
      aws_url: process.env.VUE_APP_AWS_URL,
      category_name: '',
      categories: null,
      subcategory_level_2: {},
      orderByFilter: 'name'
    }
  },
  methods: {
    getProductsByCategory () {
      this.subcategory_level_2.id = null
      this.subcategory_level_2.name = null
      this.subcategory_level_2.level_1 = null
      axios.get(`${this.app_url}api/products/categoryNew/` + this.$route.params.category_id).then((response) => {
        this.products = response.data
        this.filterOrderBy(this.orderByFilter)
      })
    },
    getProductsBySubcategoryNoSecondLevel (id, name) {
      this.subcategory_level_2.id = null
      this.subcategory_level_2.name = null
      this.subcategory_level_2.level_1 = name
      axios.get(`${this.app_url}api/products/getProductsBySubcategoryNoSecondLevel/`+ id).then((response) => {
        this.products = response.data
        this.filterOrderBy(this.orderByFilter)
      })
    },
    filterOrderBy (val) {
      this.orderByFilter = val
      switch (val) {
        case 'name':
          this.products.sort(this.orderByName)
          break
        case 'price':
          this.products.sort(this.orderByPrice)
          break
        default:
          break
      }
    },
    orderByPrice (a, b) {
      if ((a.price - a.discount) < (b.price - b.discount)) {
        return -1
      }
      if ((a.price - a.discount) > (b.price - b.discount)) {
        return 1
      }
      return 0
    },
    orderByName (a, b) {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    },
    getProductsBySubcategoryFirstLevel (id, name) {
      this.subcategory_level_2.id = null
      this.subcategory_level_2.name = null
      this.subcategory_level_2.level_1 = name
      axios.get(`${this.app_url}api/products/getProductsBySubcategoryFirstLevel/` + id).then((response) => {
        this.products = response.data
        this.filterOrderBy(this.orderByFilter)
      })
    },
    getProductsBySubcategory (id, name, level1) {
      this.subcategory_level_2.id = id
      this.subcategory_level_2.name = name
      this.subcategory_level_2.level_1 = level1
      axios.get(`${this.app_url}api/products/getProductsBySubcategorySecondLevel/` + id).then((response) => {
        this.products = response.data
        this.filterOrderBy(this.orderByFilter)
      })
    },
    closeAddProductModal () {
      this.add_product = false
    },
    // checkProductOnBasket (productId) {
      // for (var i = 0; i < this.basket.length; i++) {
      //   if (this.basket[i].id === productId) {
      //     return true
      //   }
      // }
      // return false
    // },
    addProductToModal (productObj = null) {
      this.add_product_modal_product = productObj
      this.add_product = true
    }
  },
  mounted () {
    this.getProductsByCategory()
    axios.get(`${this.app_url}api/products/getCategoriesAndSubCategories/` + this.$route.params.category_id).then((response) => {
      this.categories = response.data
    })
  },
  computed: {
    basket () {
      return this.$store.state.basket.basket
    }
  },
  watch: {
    subcategory_level_2 () {
      this.subcategory_level_2 = (this.subcategory_level_2 + 0)
    }
  }
}
</script>

<style lang="scss">
.subcategory_label{
  padding: 2%;
}
.subcategory_active{
  color: white;
  background-color: #78C828;
  border-radius: 10px;
}
.subcategory_active>p{
  margin: 0!important;
}
.subcategory-name{
  font-size: 20px;
  // text-transform: capitalize;
}
.section__bar>div>p{
  // margin: 0!important;
  margin-bottom: 0!important;
}
.section__bar{
  display: flex;
  align-content: center;
  align-items: center;
  padding: 0px 5% !important;
  margin-top:20px;
}
    .products {
      background-color: #f8f8f8;
      .category-page {
        background-image: none;
        background-color: #f8f8f8;
      }

      .title__container {
        width: 100%;
        padding: 0 calc(5% + 20px);
        margin: 15px 0px;

        .back-btn-w {
          @include font(16px, 400, $black);
          background: transparent;
          border: 0px;
          outline: 0px;
          margin-top: 0px;
          cursor: pointer;
          width: 10px;

          i {
            font-size: 14px;
            margin-right: 10px;
          }
        }

        .back-btn-w.back-desktop {
          margin-top: 35px;
        }

        h2 {
          font-family: $principal;
          font-weight: 900;
          font-size: 32px;
          margin: 0px;
        }

        button {
          float: left;
          width: 100px;
          margin: 10px 15px;
          color: $white;
          background-color: $green-dark;
          text-transform: none;
        }
      }

      .items__container {
        // justify-content: space-between;
        display: flex;
        margin-bottom: 45px;
        position: relative;
        width: 100%;
        flex-wrap: wrap;
        padding: 0!important;

        .item__container {
          width: 100%;
        }

        .item__container {
          .product_item {
            margin-bottom: 20px;
          }
        }

      }

      .row {
        padding: 0 5%;
      }

      @media (max-width: $breakpoint-xs-max) {
          .title__container {
              padding: 0 20px;

              h2 {
                  font-size: 24px;
                  line-height: initial;
              }
          }

          .product_item {
            min-height: 450px!important;
            // background-color: yellow!important;
            box-shadow: none;

            .item__labels {
              margin-bottom: 30px;
              .item__season {
                left: -5px;
                min-width: unset;
                text-align: left;
                max-width: 100px;
              }
              .item__discount {
                right: 5px;
                width: 40px;
                height: 40px;
                padding: 12px 0;
              }
            }
            img.item__image {
              max-width: auto;
              height: 100px;
              padding: 0;
            }
          }
      }
    }
   @media only screen and (max-width: 600px) {
    .products-container{
      width: 100%!important;
    }
    .category-name{
      display: none;
    }
  }

</style>
