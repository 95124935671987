<template>
  <div class="q-my-sm card_product col-md-4 col-sm-6">
    <div class="product_item" :class="[featured_color]">
      <!-- LABELS -->
      <div class="item__labels">
        <div v-if="product.new" class="item__season">Nuevo</div>
        <div v-else-if="product.seasonal" class="item__season">Producto de Temporada</div>
        <div v-if="promotion">
          <div class="item__season" style="z-index: 100;" v-if="promotion.pivot.is_a_gift === 0">{{promotion == 'extra product' ? 'Producto Extra' : promotion.type }}</div>
          <div class="item__season_gift" style="z-index: 100;" v-else-if="promotion.pivot.is_a_gift === 1">Regalo {{promotion == 'extra product' ? 'Producto Extra' : promotion.type }}</div>
        </div>
        <div v-if="product.type_discount === 0 && product.discount > 0" class="item__discount">-{{ product.discount }}%</div>
        <div v-else-if="product.type_discount === 1 && product.discount > 0" class="item__discount">-${{ product.discount }}</div>
      </div>
      <!-- FIN LABELS -->
      <q-img alt="image" v-bind:src="aws_url + product.image" class="item__image full-width" fit="contain"/>
      <div class="item__info">
        <div v-if="product.type_discount === 0 && product.discount > 0" class="item__price">MX $ {{ (product.price - (product.price / 100) * product.discount).toFixed(2) }}/{{ sellTypeFilter(product) }}</div>
        <div v-else-if="product.type_discount === 1 && product.discount > 0" class="item__price">
          MX $<br>
          <div class="price__ellipsis">{{ (product.price - product.discount).toFixed(2) }}/{{ sellTypeFilter(product) }}</div>
          <div v-if="(product.type_discount === 0 || product.type_discount === 1) && product.discount > 0" class="item__price-original">
            ${{ product.price }}
          </div>
        </div>
        <div v-else-if="product.selltype.id !== 1" class="item__price">MX $ <br>
          <div class="price__ellipsis">{{ product.price }}/{{ product.selltype.name }}</div>
          <div class="item__price-original">
            <br>
          </div>
        </div>
        <div v-else class="item__price">MX $<br>
          <div class="price__ellipsis">{{ product.price }}/{{ sellTypeFilter(product) }}</div>
          <div class="item__price-original">
            <br>
          </div>
        </div>
        <div class="item__title">{{ product.name }}</div>
      </div>
      <div class="item__section text-center">
        <button v-if="featured_color" :disabled="checkProductOnBasket(product.id) || product.soldout ? true : false" @click="go_sign_up = true" class="plus-btn"><i class="icon icon-plus"></i></button>
        <q-btn v-else unelevated rounded :disable="checkProductOnBasket(product.id) || product.soldout ? true : false" label="Agregar" class="item__button" @click="go_sign_up = true"></q-btn>
      </div>
      <div v-if="checkProductOnBasket(product.id)" class="on__basket">
        <span class="icon icon-canasta2"></span>
        <span >En la canasta</span>
      </div>
      <div v-else-if="product.soldout && !featured_color" class="soldout">
        <span >Agotado</span>
      </div>
      <div v-else-if="product.soldout && featured_color" class="soldout-featured">
        <span >Agotado</span>
      </div>
      <div v-else class="on__basket">
        <span></span>
        <span ></span>
      </div>
    </div>

    <q-dialog v-model="go_sign_up" persistent>
      <q-card class="not_account_dialog">
        <q-card-section class="items-center">
          <q-avatar icon="lock" color="primary" text-color="white" />
          <span class="q-ml-sm">¡No has ingresado!</span>
        </q-card-section>

        <q-card-actions style="display:flex;">
          <q-btn flat label="Cancelar" color="primary" v-close-popup />
          <q-btn flat label="Login" color="primary" @click.prevent="goToSignUp" />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="add_product">
      <AddProduct :product="add_product_modal_product" @close-add-product-modal="closeAddProductModal()"></AddProduct>
    </q-dialog>
  </div>
</template>

<script>
import AddProduct from './Modals/AddProduct'

export default {
  name: 'ProductCard',
  props: {
    product: {
      type: Object,
      required: true
    },
    featured_color: {
      type: String,
      default: ''
    }
  },
  components: {
    AddProduct
  },
  data () {
    return {
      go_sign_up: false,
      add_product: false,
      add_product_modal_product: null,
      promotion: null,
      aws_url: process.env.VUE_APP_AWS_URL
    }
  },
  mounted () {
    this.activePromotion(this.product)
  },
  methods: {
    addProductToModal (productObj = null) {
      this.add_product_modal_product = productObj
      this.add_product = true
    },
    closeAddProductModal () {
      this.add_product = false
    },
    goToSignUp() {
      this.$router.push('/login')
    },
    checkProductOnBasket (productId) {
      for (var i = 0; i < this.basket.length; i++) {
        if (this.basket[i].id === productId) {
          return true
        }
      }
      return false
    },
    activePromotion (product) {
      product.promotions.forEach(promotion => {
        if (promotion.active === 1) {
          this.promotion = promotion
        }
      })
    },
    sellTypeFilter (product) {
      if (product.selltype.id === 1) {
        return 'kg'
      } else {
        return product.selltype.name
      }
    }
  },
  computed: {
    basket () {
      return this.$store.state.basket.basket
    }
  }
}
</script>

<style scoped lang="scss">
  .item__section{
    display: flex;
    justify-content: center;
  }
  .price__ellipsis{
    // position: relative;
    // display: flex;
    // width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden!important;
  }
  .card_product{
    position: relative;
    // width: 33%;
    width: 100%;
    // padding: 0, i!important;
  }
  .product_item{
    position: relative;
    margin: 0 10px;
    padding: 30px 0px 0px 0px;
    border-radius: 6px;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.3);
    text-align: center;
    background-color: white;

    .item__labels {
      .item__discount {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 10;
        background-color: $red;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
        border-radius: 500px;
        padding: 15px 5px;
        width: 45px;
        height: 45px;
        @include font(12px, 900, $white);
      }
      .item__season {
        position: absolute;
        top: 15px;
        left: -10px;
        background-color: $red;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
        border-radius: 0px;
        padding: 8px 15px;
        min-width: 100px;
        @include font(12px, 900, $white);
      }
      .item__season_gift {
        position: absolute;
        top: 15px;
        left: -10px;
        background-color: $green;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
        border-radius: 0px;
        padding: 8px 15px;
        min-width: 100px;
        @include font(12px, 900, $white);
      }
    }

    img.item__image {
      display: inline-block;
      padding-top: 25px;
      max-height: 160px;
      width: 100%;
      height: auto;
      text-align: center;
    }

    .item__image {
      display: inline-block;
      padding-top: 25px;
      max-height: 160px;
      text-align: center;
    }

    .item__info {
      margin-bottom: 15px;
      .item__price {
        @include font(20px, 900, $green);
        text-align: left;
        padding: 0px 20px;
      }
      .item__price-original {
        // background-color: red;
        position: relative;
        @include font(16px, 900, $border-gray);
        text-align: left;
        margin-top: auto;
        margin-bottom: auto;
        text-decoration: line-through;
        padding: 0px 10px;
        text-decoration-color: $red;
      }

      .item__title {
        @include font(16px, 500, $black);
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0px 20px;
      }
    }

    .item__button {
      background-color: $green;
      padding: 5px 45px;
      @include font(16px, 900, $white);
      text-align: center;
      display: flex;
      margin-left: 20px;
      text-transform: capitalize;
      border-radius: 10px !important;
    }

    .on__basket {
      margin-top: 15px;
      span {
        margin-left: 5px;
        margin-right: 15px;
        @include font(14px, 900, $gray-light);
      }
    }

    .soldout {
      margin-top: 15px;
      span {
        margin-left: 5px;
        margin-right: 15px;
        @include font(15px, 900, $red);
      }
    }

    .soldout-featured {
      margin-top: 15px;
      span {
        margin-left: 5px;
        margin-right: 15px;
        @include font(15px, 900, $white);
      }
    }

    &.green{ background-color: #7bb127; }
    &.yellow{ background-color: #ffd74d; }
    &.green, &.yellow {
      .item__info {
        .item__price, .item__title, .item__price-original {
          color: $white;
        }
      }

      .plus-btn {
        border: 0px;
        background: transparent;
        outline: 0;

        i {
          font-size: 38px;
        }
      }

      .on__basket span { color: white; }
      .icon-canasta2:before { color: white; }
    }

    @media (max-width: 991px) {
      // &.green, &.yellow { padding-bottom: 15px; }
    }

    @media (max-width: $breakpoint-xs-max) {
      padding-bottom: 15px;
      margin: 0 10px;
      padding-bottom: 20px;
      &.green, &.yellow { box-shadow: none; }

      img.item__image {
        height: 100px;
        padding: 15px;
      }

      .item__info {
        .item__price {
          font-size: 14px;
        }

        .item__price-original {
          font-size: 10px;
        }

        .item__title {
          font-size: 17px;
        }

        .item__button {
          margin-top: 10px;
          font-size: 12px;
          padding: 5px 15px;
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
     .card_product{
       position: relative;
       width: 95%;
       margin: 10px 10px;
       background-color: white;
       border-radius: 10px;
       padding: 10px 10px;
      //  min-height: 450px!important;
      //  background-color: red;
      //  padding: 0, i!important;
     }
     .item__section{
      display: flex;
      justify-content: center;
     }
     .item__button {
      margin: 0!important;
      padding: 4% 15% !important;
     }
     .product_item{
      margin: 0!important;
      min-height: 450px!important;
      // background-color: red;
     }
  }
</style>
