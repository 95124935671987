<template>
  <div class="product_filter_container flex">
    <!-- Order by: -->
    <div class="container_filter items-center q-py-sm q-px-md q-ml-md category-filter">
      <p>Categoría: </p>
      <select name="" id="" @change="filterCategory" v-model="category">
        <option disabled value="0">Selecciona una opción</option>
        <option v-for="level_1 in filteredLevelOne" :key="'no_second_level'+level_1.id" :value="[level_1.id, level_1.name, level_1.has_second_level]">
          {{level_1.name}}
        </option>
        <!-- <optgroup v-if="second_level === 1 && filteredLevelOne" :label="categories.category.name">
        </optgroup> -->
        <optgroup v-for="level in levelTwo" :key="level.id" :label="level.name">
          <option v-for="second_level in level.subcategories_level_2" :key="second_level.id" :value="[second_level.id, second_level.name, second_level.has_second_level, level.name]">{{second_level.name}}</option>
          <!-- <option value="">{{level.subcategories_level_2}}</option> -->
        </optgroup>
      </select>
    </div>
    <div class="container_filter items-center q-py-sm q-px-md q-ml-md">
      <p>Ordenar por: </p>
      <select name="" id="" @change="filterOrderBy" v-model="orderByFilter">
        <option value="name">Nombre</option>
        <option value="price">Precio</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    categories: Object
  },
  data () {
    return {
      orderByFilter: 'name',
      category: 0,
      second_level: 0,
      level_one: null
    }
  },
  computed: {
    filteredLevelOne () {
      let filteredLevelOne = []
      this.categories.subcategories_level_1.forEach(levelOne => {
        if (levelOne.has_second_level === 0 && (levelOne.name !== 'otros' && levelOne.name !== 'Otros')) {
          filteredLevelOne.push(levelOne)
        }
      })
      return filteredLevelOne
    },
    levelTwo () {
      let filteredLevelTwo = []
      this.categories.subcategories_level_1.forEach(levelOne => {
        if (levelOne.has_second_level !== 0) {
          filteredLevelTwo.push(levelOne)
        }
      })
      return filteredLevelTwo
    }
  },
  methods: {
    filterOrderBy () {
      this.$emit('filterOrderBy', this.orderByFilter)
    },
    filterCategory () {
      let id = this.category[0]
      let name = this.category[1]
      let secondName = this.category[3]
      if (this.category[2] === 0) {
        this.$emit('getProductsBySubcategoryNoSecondLevel', id, name)
      } else {
        this.$emit('getProductsBySubcategory', id, name, secondName)
      }
    }
  },
  mounted () {
    this.categories.subcategories_level_1.forEach(subcategory => {
      if (subcategory.has_second_level !== 0) {
        this.second_level = 1
      }
    })
  }
}
</script>

<style scoped>
.category-filter{
  display: none!important;
}
.container_filter{
  display: flex;
  background-color: white;
  border-radius: 4px;
  border: solid;
  border-color: gray;
  border-width: 2px;
}
.container_filter>p{
  margin: 0!important;
}
.container_filter>select{
  font-weight: bold;
  background: transparent;
  border: none;
}
.container_filter>select:focus{
  outline: none;
}
@media only screen and (max-width: 600px) {
  .product_filter_container{
    width: 100%;
  }
  .container_filter{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 2%;
    margin-bottom: 4%;
  }
  .category-filter{
    display: flex!important;
  }
}
</style>
