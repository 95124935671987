<template>
  <q-card class="add__product-modal">
      <q-card-section class="row items-center card__header">
          <q-space ></q-space>
          <q-btn icon="close" flat round dense v-close-popup ></q-btn>
      </q-card-section>

      <q-card-section>
          <div class="row">
              <div class="col col-5 left-side" v-if="product && product.selltype.id === 1">
                  <q-img alt="image" v-bind:src="aws_url + product.image"  width="150px" class="add__product-image"/>

                  <div v-if="kg" class="quantity__container">
                      <button @click="minusKg" class="minus"><i class="fas fa-minus"></i></button>
                      <div class="quantity__container-input">
                          <q-input outlined v-model="quantity_kg" type="text" suffix="kg.  " :decimals="1" :step="0.1" class="input_quantity">
                          </q-input>
                          <button class="quantity__type" @click="toggle"><img src="~assets/icons/flechas.svg" alt="flecha"></button>

                          <div v-if="quantity__choice" class="quantity__choice">
                              <button @click="choice(1)">
                                  <span>
                                      <img src="~assets/icons/piezas.svg" alt="kilogramos">
                                  </span>Piezas
                              </button>
                              <button @click="choice(2)">
                                  <span>
                                      <img src="~assets/icons/kg.svg" alt="kilogramos">
                                  </span>Peso
                              </button>
                          </div>
                      </div>
                      <button @click="plusKg" class="plus"><i class="fas fa-plus"></i></button>
                  </div>

                  <div v-if="pieza" class="quantity__container">
                      <button @click="minusPz" class="minus"><i class="fas fa-minus"></i></button>
                      <div class="quantity__container-input">
                          <q-input outlined v-model="quantity_pz" type="number" suffix="pz.  " :decimals="1" :step="1" class="input_quantity native-controls-hidden"
                           :rules="[val => (parseFloat(val) % 1 === 0) || 'Por favor ingrese un valor entero']"
                          >
                          </q-input>
                          <button class="quantity__type" @click="toggle"><img src="~assets/icons/flechas.svg" alt="flecha"></button>

                          <div v-if="quantity__choice" class="quantity__choice">
                              <button @click="choice(1)">
                                  <span>
                                      <img src="~assets/icons/piezas.svg" alt="kilogramos">
                                  </span>Piezas
                              </button>
                              <button @click="choice(2)">
                                  <span>
                                      <img src="~assets/icons/kg.svg" alt="kilogramos">
                                  </span>Peso
                              </button>
                          </div>
                      </div>
                      <button @click="plusPz" class="plus"><i class="fas fa-plus"></i></button>
                  </div>
              </div>
              <div class="col col-5 left-side" v-else>
                  <q-img alt="image" v-bind:src="aws_url + product.image"  width="150px" class="add__product-image"/>
                  <div class="quantity__container">
                      <button @click="minusPz" class="minus"><i class="fas fa-minus"></i></button>
                      <div class="quantity__container-input">
                          <q-input outlined v-model="quantity_pz" type="number" :decimals="0" :step="1" class="input_quantity native-controls-hidden"
                           :rules="[val => (parseFloat(val) % 1 === 0) || 'Por favor ingrese un valor entero']"
                          >
                          </q-input>
                      </div>
                      <button @click="plusPz" class="plus"><i class="fas fa-plus"></i></button>
                  </div>
              </div>
              <div class="col col-7 right-side">
                  <h4>{{ product.name }}</h4>

                  <p class="description">{{ product.description }}</p>
                  <div v-if="product.selltype.id === 1">
                    <p>Precio: <span>MX${{ realPrice(product).toFixed(2) }}/kg</span></p>
                    <p>Precio por pieza:* <span>MX${{ ((product.average_weight / 1000 ) * realPrice(product)).toFixed(2) }}/pz</span></p>
                    <div class="quantity__legend">*El precio del producto por pieza es aproximado</div>
                  </div>
                  <div v-else>
                    <p>Precio: <span>MX${{ realPrice(product).toFixed(2) }}/{{product.selltype.name}}</span></p>
                  </div>
                  <div v-if="product.promotions">
                    <div v-for="promo in product.promotions" :key="promo.id">
                      <span v-if="promo.pivot.is_a_gift === 1 && promo.pivot.product_id == product.id">
                       Producto de regalo en el combo: {{promo.name}}
                      </span>
                    </div>
                  </div>
                  <p class="notes">Agregar instrucciones adicionales:</p>
                  <q-input
                    v-model="additional_instructions"
                    :rows="3"
                    class="product-textarea"
                    placeholder="Agrega instrucciones para tu producto"
                    filled
                    type="textarea"
                    :rules="[ val => val.length <= 180 || 'Porfavor utilice menos de 180 caracteres']"
                  />
                  <q-checkbox v-if="product.slice" v-model="cut" label="Cortar en trozos" color="#540494"></q-checkbox>
                  <q-btn class="add" @click="addProductOnBasket(product)" :disabled="!can_request_product || (additional_instructions !== null && additional_instructions.length > 180)">Agregar a la canasta</q-btn>
                  <div v-if="product.promotions">
                    <div v-for="promotion in product.promotions" :key="promotion.id">
                      <div v-if="promotion.type === 'combo'">
                          <q-btn class="add_promo" @click="checkPromotionValidity(product)" :disabled="!can_request_product || (additional_instructions !== null && additional_instructions.length > 180)">
                          Agregar promoción
                        </q-btn>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
      </q-card-section>
  </q-card>
</template>

<script>
import axios from 'axios'
import Vue2Filters from 'vue2-filters'

export default {
  inherit: true,
  added_promotion: false,
  requiredProducts: [],
  props: ['product'],
  mixins: [Vue2Filters.mixin],
  name: 'PageIndex',
  data () {
    return {
      add_product: false,
      quantity_kg: 1,
      quantity_pz: 1,
      quantity__choice: false,
      cut: false,
      kg: true,
      pieza: false,
      choice_type: '2',
      aws_url: process.env.VUE_APP_AWS_URL,
      app_url: process.env.VUE_APP_API_URL,
      additional_instructions: null
    }
  },
  computed: {
    can_request_product: function () {
      if (this.kg && this.quantity_kg > 0) {
        return true
      }
      if (this.pieza && this.quantity_pz >= 1) {
        return parseInt(this.quantity_pz) >= 1 && parseFloat(this.quantity_pz) % 1 === 0
      }
      if (!(this.product && this.product.selltype.id === 1)) {
        return parseInt(this.quantity_pz) >= 1 && parseFloat(this.quantity_pz) % 1 === 0
      }
      return false
    }
  },
  mounted () {
    if (this.product.selltype.id !== 1) {
      this.pieza = true
      this.kg = false
      this.choice_type = '1'
    }
  },
  methods: {
    toggle () {
      this.quantity__choice = !this.quantity__choice
    },
    minusKg () {
      this.quantity_kg = parseFloat(this.quantity_kg)
      if (this.quantity_kg > 0.0) {
        this.quantity_kg = parseFloat(this.quantity_kg - 0.1)
        this.quantity_kg = parseFloat(this.quantity_kg.toFixed(1))
      }
    },
    plusKg () {
      this.quantity_kg = parseFloat(this.quantity_kg)
      this.quantity_kg = parseFloat(this.quantity_kg + 0.1)
      this.quantity_kg = parseFloat(this.quantity_kg.toFixed(1))
    },
    minusPz () {
      this.quantity_pz = parseFloat(this.quantity_pz)
      if (this.quantity_pz > 1) {
        this.quantity_pz = parseFloat(this.quantity_pz - 1)
      }
    },
    plusPz () {
      this.quantity_pz = parseFloat(this.quantity_pz)
      this.quantity_pz = parseFloat(this.quantity_pz + 1)
    },
    choice (a) {
      this.quantity_kg = 1
      this.quantity_pz = 1
      this.choice_type = a
      this.quantity__choice = !this.quantity__choice
      this.kg = false
      this.pieza = false
      if (a === 2) {
        this.kg = true
      } else if (a === 1) {
        this.pieza = true
      }
    },
    checkPromotionValidity (data) {
      let promotion = data.promotions[0]
      let promotionValidity = promotion.expiration_date
      var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      let expiration = new Date(promotionValidity)
      let parsed = expiration.toLocaleDateString('es-MX', options)
      let now = new Date()
      if (expiration < now || promotion.active === 0) {
        this.$q.notify({
          message: '<p style="text-align: center; padding-top: 5px;">Esta promoción ya no es válida  (' + parsed + ') </p>',
          color: 'red-10',
          position: 'bottom',
          html: true
        })
        return
      }
      this.addPromotionOnBasket(data)
    },
    addPromotionOnBasket (data) {
      let promo = data.promotions[0].id
      // let qty = data.promotions[0].pivot.quantity
      axios.post(`${this.app_url}api/promotions/requiredProductsForPromo/${promo}`).then(response => {
        this.requiredProducts = response.data
        this.$store.state.basket.basket.map(basket => {
          this.requiredProducts.map(productPromotion => {
            if (basket.id === productPromotion.id) {
              let index = this.requiredProducts.indexOf(productPromotion)
              this.requiredProducts.splice(index, 1)
            }
          })
        })
        this.requiredProducts.map(addUpdatedPromotion => {
          let requiredQty = addUpdatedPromotion.required_quantity
          addUpdatedPromotion.choice_type = addUpdatedPromotion.ums === 'Pz' ? 1 : 2
          addUpdatedPromotion.quantity_kg = Number(requiredQty).toFixed(2)
          addUpdatedPromotion.quantity_pz = requiredQty
          addUpdatedPromotion.price_with_discount = this.realPrice(addUpdatedPromotion).toFixed(2)
          addUpdatedPromotion.kg = addUpdatedPromotion.ums === 'Pz' ? 0 : 1
          addUpdatedPromotion.pieza = addUpdatedPromotion.ums === 'Pz' ? 1 : 0
          addUpdatedPromotion.sliced = 0
          addUpdatedPromotion.additional_instructions = this.additional_instructions
          this.addProductOnBasket(addUpdatedPromotion, true)
        })
      })
    },
    addProductOnBasket (data, force = false) {
      data.choice_type = this.choice_type
      data.quantity_kg = force ? data.quantity_kg : Number(this.quantity_kg).toFixed(2)
      data.quantity_pz = force ? data.quantity_pz : this.quantity_pz
      data.price_with_discount = this.realPrice(data).toFixed(2)
      data.kg = this.kg
      data.pieza = this.pieza
      data.sliced = this.cut
      data.additional_instructions = this.additional_instructions
      this.$store.commit('basket/pushBasket', data)
      this.$q.notify({
        message: data.name + ' agregado a la canasta.',
        color: 'green-10',
        position: 'bottom'
      })
      this.$emit('close-add-product-modal')
      let params = {}
      params[window.FB.AppEvents.ParameterNames.CONTENT] = data.name
      params[window.FB.AppEvents.ParameterNames.CONTENT_ID] = data.id
      params[window.FB.AppEvents.ParameterNames.CONTENT_TYPE] = 'product'
      params[window.FB.AppEvents.ParameterNames.CURRENCY] = 'MXN'
      window.FB.AppEvents.logEvent(window.FB.AppEvents.EventNames.ADDED_TO_CART, data.price_with_discount, params)

      window.fbq('track', 'AddToCart', {
        content_ids: [data.id],
        title: data.name,
        price: data.price,
        availability: 'in stock',
        image_link: this.aws_url + data.image,
        description: data.description,
        link: this.app_url + '/products/' + data.category_id,
        content_name: data.name,
        content_type: 'product',
        currency: 'MXN',
        value: 1
      })
    },
    realPrice (product) {
      if ((product.type_discount === 0 || product.type_discount === '0') && product.discount > 0) {
        return product.price - (product.price / 100) * product.discount
      } else if ((product.type_discount === 1 || product.type_discount === '1') && product.discount > 0) {
        return product.price - product.discount
      } else {
        return Number(product.price)
      }
    }
  }
}
</script>

<style lang="scss">
    body.login {
        background-image: none !important;
        background-color: white;
    }

    .row {
        padding: 0 5%;
    }

    .card__header {
      padding-top: 15px;

      .on__basket {
        span {
          margin-left: 5px;
          margin-right: 15px;
          font-family: $principal;
          font-size: 14px;
          font-weight: 900;
          color: $gray-light;
        }
      }
    }

    .left-side {
      padding: 10px;
    }

    .add__product-image {
      width: 100%;
      max-width: 100%;
      height: auto;
      padding: 10px;
      margin-bottom: 9px;
    }

    .right-side {
      padding: 10px;

      h4 {
        margin: 0px 0px 10px 0px;
        font-family: $principal;
        font-weight: bold;
        font-size: 15px;
        // background-color: red;
        max-height: 20%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        // font-style: bold;
      }

      p {
        margin: 0px 0px 5px 0px;
        font-family: $principal;
        font-weight: lighter;
        font-size: 14px;
        width: 100%;
        float: left;

        span {
          float: right;
          font-size: 18px;
        }
      }

      p.notes {
        margin-top: 25px;
      }

      p.description {
        margin: 0px 0px 15px 0px;
      }

      .product-textarea.q-textarea {
        padding: 0 0.5%;
      }

      /*textarea {
        color: $gray;
        background-color: $gray-lighter;
        border: 0px;
        resize: none;
        float: left;
        width: 100%;
        border-radius: 6px;
      }*/

      .q-checkbox {
        padding: 0px !important;
        margin-left: -10px;
      }

      .q-checkbox__inner--active, .q-checkbox__inner--indeterminate {
        color: $green;
        color: $green;
      }

      .q-checkbox__label {
        font-family: $principal;
        font-weight: 900;
      }

      button.add {
        float: left;
        width: 100%;
        margin: 25px 0px 10px 0px;
        color: $white;
        background-color: $green;
        text-transform: none;
        border-radius: 6px;
        padding: 10px 25px;
        border: 0px;
        font-family: $principal;
        font-weight: 900;
        font-size: 16px;
      }
      button.add_promo {
        float: left;
        width: 100%;
        margin: 5px 0px 10px 0px;
        color: $white;
        background-color: $orange;
        text-transform: none;
        border-radius: 6px;
        padding: 1px 15px;
        border: 0px;
        font-family: $principal;
        font-weight: 900;
        font-size: 14px;
      }
    }

    .add__product-image {
      width: 100%;
      max-width: 100%;
      height: auto;
      padding: 10px;
      margin-bottom: 9px;
    }

    .quantity__container {
      display: inline-block;
      text-align: center;
      .quantity__container-input {
        position: relative;
        width: auto;
        display: initial;

        .quantity__type {
            position: absolute;
            right: 13px;
            border-radius: 0px;
            width: 20px;
            background: transparent;
            height: 40px;
            padding: 12px 0px;
            cursor: pointer;
        }

        .quantity__choice {
            position: absolute;
            right: 12px;
            width: 93px;
            border-radius: 6px;
            box-shadow: 1px 1px 15px rgba(0,0,0,0.1);
            top: -90px;
            background-color: $white;

            button {
                width: 100%;
                border-radius: 6px;
                background: $white;
                color: $black;
                font-family: $principal;
                font-size: 12px;
                text-align: left;
                padding: 0px 15px;
                cursor: pointer;

                span {
                    width: 30px;
                    img {
                        height: 15px;
                        margin-right: 10px;
                        margin-bottom: -2px;
                        text-align: left;
                    }
                }
            }
        }
      }
      .input_quantity {
        width: calc(100% - 100px);
        height: 35px;
        display: inline-block;
        text-align: center;
        font-family: $principal;
        font-weigth: 900;
      }

      .q-field__control-container {
        height: 40px;
        outline: 0;
      }

      .q-field__native {
        font-weight: 900;
      }

      .q-field--outlined .q-field__control {
        padding-right: 21px;
        height: 40px;
      }

      .q-field--outlined .q-field__control:after {
        border: 0px;
        height: 40px;
      }

      .q-field--outlined .q-field__control:before {
        height: 40px;
        border: 0px;
        background-color: $gray-lighter;
      }

      button {
        border-radius: 500px;
        height: 35px;
        width: 35px;
        border: 0px;
        outline: 0;
        color: white;
        font-weight: 900;
        display: inline-block;
        text-align: center;
      }

      .minus {
        background-color: $orange;
        touch-action: manipulation;
      }

      .plus {
        background-color: $green;
        touch-action: manipulation;
      }
    }

    .quantity__legend {
      font-family: $principal;
      font-size: 12px;
      color: $red;
      line-height: 14px;
    }

    .add__product-modal {
        min-width: 660px;
        border-radius: 16px;
        .q-toolbar {
            padding: 13px 15px !important;
        }
    }

    @media (max-width: $breakpoint-xs-max) {

        .q-toolbar__title:first-child {
            width: 100%;
            float: left;
            padding: 0px;
            margin: 0px;
            text-align: center;

            img {
                text-align: center;
            }
        }

        .q-card__section {
            padding: 0px 10px;
        }

        .add__product-modal {
            min-width: 90%;
            padding: 15px 0px;
            height: 100%!important;

            .q-card__section {
                padding: 0px;
            }

            .card__header {
                padding: 0 10px;
            }

            .left-side {
                width: 100%;
            }

            .right-side {
                width: 100%;

                p {
                    span {
                        font-size: 16px;
                        font-weight: 900;
                    }
                }
            }
        }

        .quantity__container {
          width: 100%;
          .quantity__container-input {
            .quantity__type {
              right: 18px;
            }
            .quantity__choice {
              position: absolute;
              right: 19px;
              width: 80%;
              button {
                padding: 0px 25px;
              }
            }
          }

          .q-field--outlined .q-field__control {
            width: 100% !important;
            min-width: 100% !important;
            max-width: 100% !important;
          }
          .q-field--outlined .q-field__control:before {
            width: 100%;
            min-width: 100%;
            max-width: 100%;
          }
        }

    }
</style>
