<template>
  <div class="col-3">
    <div class="bg-white q-pa-md q-mt-md categories_menu_bg q-mr-lg">
      <p v-if="second_level === 1" class="text-bold subcategory-name cursor-pointer" @click="getProductsByCategory">{{categories.category.name}}</p>
      <div v-for="level_1 in categories.subcategories_level_1" :key="'no_second_level'+level_1.id">
        <p :class="'subcategory_label cursor-pointer '+ (level_one == level_1.id ? 'subcategory_active' : '')" v-if="level_1.has_second_level === 0 &&(level_1.name !== 'otros' &&  level_1.name !== 'Otros')" @click="getProductsBySubcategoryNoSecondLevel(level_1.id, level_1.name)">{{level_1.name}}</p>
      </div>
      <div v-for="level_1 in categories.subcategories_level_1" :key="'level_1'+level_1.id">
        <p class="text-bold subcategory-name cursor-pointer" v-if="level_1.has_second_level === 1" @click="getProductsBySubcategoryFirstLevel(level_1.id, level_1.name)">{{level_1.name}}</p>
        <div :class="'cursor-pointer subcategory_label ' + (subcategory_level_2.id == level_2.id ? 'subcategory_active' : '')" v-for="level_2 in level_1.subcategories_level_2" :key="'level_2'+level_2.id" @click="getProductsBySubcategory(level_2.id, level_2.name, level_1.name)">
          <p v-if="(level_2.name !== 'otros' &&  level_2.name !== 'Otros')">
            {{level_2.name}}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    categories: Object,
    subcategory_level_2: Object
  },
  data () {
    return {
      second_level: 0,
      level_one: null
    }
  },
  mounted () {
    this.categories.subcategories_level_1.forEach(subcategory => {
      if (subcategory.has_second_level === 0) {
        this.second_level = 1
      }
    })
  },
  methods: {
    setLevel1 (id) {
      this.level_one = id
    },
    getProductsBySubcategoryNoSecondLevel (id, name) {
      this.setLevel1(id)
      this.$emit('getProductsBySubcategoryNoSecondLevel', id, name)
    },
    getProductsBySubcategoryFirstLevel (id, name) {
      this.level_one = null
      this.$emit('getProductsBySubcategoryFirstLevel', id, name)
    },
    getProductsBySubcategory (id, secondLevel, firstLevel) {
      this.$emit('getProductsBySubcategory', id, secondLevel, firstLevel)
    },
    getProductsByCategory () {
      this.level_one = null
      this.$emit('getProductsByCategory')
    }
  }
}
</script>

<style scoped>
.subcategory-name{
  font-size: 20px;
  /* text-transform: capitalize; */
}
.categories_menu_bg{
  border-radius: 10px;
}
.item-menu{
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .categories_menu_bg{
    display: none;
  }
}
</style>
