<template>
  <q-page class="category-page" >
    <!-- Menú indicator -->
    <div>
      <!-- Products container -->
      <div class="col-9 q-pa-md products-container">
          <a href="/" style="font-family: 'Nunito', sans-serif; text-decoration: none; margin-left: 5px;">
            <img class="q-mr-auto" src="~assets/icons/home_alt_outline.svg" alt="home" width="25" height="25">
          </a>
        <div class="flex items-center q-mx-sm">
          <p class="text-bold q-mt-md q-mr-auto" style="font-size: 30px; color: #7bb127;">{{section_name}}</p>
        </div>
        <div class="items__container row" v-if="products.length">
          <div :product="product" v-for="(product) in products" class="col-md-4 col-xs-6" :key="product.id">
            <ProductCardSectionExplore :product="product"></ProductCardSectionExplore>
          </div>
        </div>
        <div v-else>
          <p>No se encontraron productos</p>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
// import Categories from '../components/Categories'
import axios from 'axios'
import Vue2Filters from 'vue2-filters'
import ProductCardSectionExplore from '../components/ProductCardSectionExplore.vue'

export default {
  mixins: [Vue2Filters.mixin],
  name: 'PageIndex',
  components: {
    ProductCardSectionExplore
  },
  beforeCreate: function () {
    document.body.className = 'products'
  },
  data () {
    return {
      add_product: false,
      quantity_kg: 0.1,
      quantity_pz: 1,
      section_id: this.$route.params.section_id,
      quantity__choice: false,
      cut: false,
      kg: true,
      pieza: false,
      choice_type: '',
      products: [],
      app_url: process.env.VUE_APP_API_URL,
      add_product_modal_product: null,
      aws_url: process.env.VUE_APP_AWS_URL,
      section_name: '',
      orderByFilter: 'name'
    }
  },
  methods: {
    closeAddProductModal () {
      this.add_product = false
    },
    // checkProductOnBasket () {
      // for (var i = 0; i < this.basket.length; i++) {
      //   if (this.basket[i].id === productId) {
      //     return true
      //   }
      // }
      // return false
    // },
    addProductToModal (productObj = null) {
      this.add_product_modal_product = productObj
      this.add_product = true
    }
  },
  mounted () {
    axios.get(`${this.app_url}api/products/productsBySection/${this.section_id}`).then((response) => {
      this.section_name = response.data[0].name
      this.products = response.data[0].products
    })
  },
  computed: {
    basket () {
      return this.$store.state.basket.basket
    }
  },
  watch: {
  }
}
</script>

<style lang="scss">
.subcategory_label{
  padding: 2%;
}
.subcategory_active{
  color: white;
  background-color: #78C828;
  border-radius: 10px;
}
.subcategory-name{
  font-size: 20px;
  text-transform: capitalize;
}
.section__bar>div>p{
  // margin: 0!important;
  margin-bottom: 0!important;
}
.section__bar{
  display: flex;
  align-content: center;
  align-items: center;
  padding: 0px 5% !important;
  margin-top:20px;
}
    .products {
      background-color: #f8f8f8;
      .category-page {
        background-image: none;
        background-color: #f8f8f8;
      }

      .title__container {
        width: 100%;
        padding: 0 calc(5% + 20px);
        margin: 15px 0px;

        .back-btn-w {
          @include font(16px, 400, $black);
          background: transparent;
          border: 0px;
          outline: 0px;
          margin-top: 0px;
          cursor: pointer;
          width: 10px;

          i {
            font-size: 14px;
            margin-right: 10px;
          }
        }

        .back-btn-w.back-desktop {
          margin-top: 35px;
        }

        h2 {
          font-family: $principal;
          font-weight: 900;
          font-size: 32px;
          margin: 0px;
        }

        button {
          float: left;
          width: 100px;
          margin: 10px 15px;
          color: $white;
          background-color: $green-dark;
          text-transform: none;
        }
      }

      .items__container {
        // justify-content: space-between;
        display: flex;
        margin-bottom: 45px;
        position: relative;
        width: 100%;
        flex-wrap: wrap;
        padding: 0!important;

        .item__container {
          width: 100%;
        }

        .item__container {
          .product_item {
            margin-bottom: 20px;
          }
        }

      }

      .row {
        padding: 0 5%;
      }

      @media (max-width: $breakpoint-xs-max) {
          .title__container {
              padding: 0 20px;

              h2 {
                  font-size: 24px;
                  line-height: initial;
              }
          }

          .product_item {
            box-shadow: none;

            .item__labels {
              margin-bottom: 30px;
              .item__season {
                left: -5px;
                min-width: unset;
                text-align: left;
                max-width: 100px;
              }
              .item__discount {
                right: 5px;
                width: 40px;
                height: 40px;
                padding: 12px 0;
              }
            }
            img.item__image {
              max-width: auto;
              height: 100px;
              padding: 0;
            }
          }
      }
    }
   @media only screen and (max-width: 600px) {
    .products-container{
      width: 100%!important;
    }
    .category-name{
      display: none;
    }
  }

</style>
