<template>
  <div class="categories-bar-menu">
    <div class="all-departments" v-if="(!this.$route.params.category_id) || (this.$route.params.category_id && this.$q.platform.is.desktop)">
      <!-- {{v-if="!this.$route.params.category_id && !$q.platform.is.mobile"}} -->
      <!-- <q-icon class="keyboard_arrow_down"></q-icon> -->
      <p style="font-size: 16px; font-weight: 600; font-family: 'Nunito', sans-serif; color: #808080; position: relative;">
        Todos los departamentos
        <q-icon class="arrow_down" size="2em" name="keyboard_arrow_down" />
      </p>
      <q-menu>
        <q-list style="min-width: 100px">
          <q-item clickable v-close-popup v-for="category in full_categories" :key="category.id">
            <q-item-section @click="viewByCategory(category.id)">{{category.name}}</q-item-section>
          </q-item>
        </q-list>
      </q-menu>
    </div>
    <!-- <div v-if="this.$route.params.category_id && this.$q.platform.is.desktop" class="categories-bar-menu-categories">
      <p v-for="category in categories_items" :key="category.id" @click="viewByCategory(category.id)" class="item-menu" style="font-size: 16px; font-weight: 600; font-family: 'Nunito', sans-serif; color: #808080; position: relative;">{{category.name}}</p>
    </div> -->
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      loader: true,
      pageOffset: 2,
      show_more: false,
      categories_items: [],
      full_categories: [],
      app_url: process.env.VUE_APP_API_URL
    }
  },
  beforeMount () {
    this.getCategories()
  },
  methods: {
    viewByCategory (category) {
      if(localStorage.getItem('token')){
        this.$router.push('/products/' + category)
      } else {
        this.$router.push('/exploreProducts/' + category).then(() => {
          this.$router.go()
        })
      }

    },
    orderCategories (a, b) {
      if (a.order < b.order) {
        return -1
      }
      if (a.order > b.order) {
        return 1
      }
      return 0
    },
    getCategories () {
      axios.get(`${this.app_url}api/categories?single`).then((response) => {
        response.data = response.data.sort(this.orderCategories)
        this.categories_items = response.data.slice(0, 6)
        this.full_categories = response.data
      })
    }
  }
}
</script>

<style scoped>
.item-menu{
  cursor: pointer;
}
.categories-bar-menu{
  width: 100%!important;
  position: relative;
  display: flex;
  margin-top: 1.5%;
  padding: 0 5% 1%;
  align-items: center;
  /* border-bottom: 1px solid lightgray; */
  /* box-shadow: 2px 5px 5px 0px rgba(0,0,0,0.19);
  -webkit-box-shadow: 2px 5px 5px 0px rgba(0,0,0,0.19);
  -moz-box-shadow: 2px 5px 5px 0px rgba(0,0,0,0.19); */
  justify-content: space-between;
}
.all-departments{
  width: 25%;
  position: relative;
}
.all-departments > p {
  display: flex;
  align-items: center;
}
.arrow_down{
  color: greenyellow!important;
}
.categories-bar-menu-categories{
  width: 80%!important;
  position: relative;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 600px) {
  .categories-bar-menu{
    /* display: none; */
    display: flex;
    justify-content: center;
  }
  .all-departments{
    width: auto;
  }
  /* .categories-bar-menu-categories{
    display: none;
  } */
}
</style>
